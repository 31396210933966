import React, { Component } from "react";

// Global
import Container from "../../components/Container";
import { materialStyle } from "../../styles";
import Spinner from "../../components/Spinner";

// Material
import { TextField, Grid, Button, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "../../components/Snackbar";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerRentalItemOptionsActions } from "../../store/ducks/lockerRentalItemOptions";
import { Actions as LockerAcions } from "../../store/ducks/locker";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import formatDate from "../../functions/formatDate";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import { Cancel as CancelIcon, DoneAll } from "@material-ui/icons";
import { constants } from "../../config";
import LockerRentalItemOptionTableAssociation from "../../components/LockerRentalItemOptionTableAssociation";

class LockerRentalItemOptionEdit extends Component {
  state = {
    currentRental: null,
    isChanged: true,
    parameters: null,
    image: "",
    open: false,
    downloadFile: false,
    idDeliveryUpdateInfoSubType: null,
    edit: false,
    currentCompartments: []
  };

  componentWillMount() {
    const {
      showLockerRentalItemOptionsRequest,
      resetLockers,
      lockersRequest
    } = this.props;
    const { id } = this.props.match.params;

    resetLockers();

    lockersRequest(`perPage=1000&getCompartments=true`);

    showLockerRentalItemOptionsRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (
        nextProps.lockerRentalItemOptions.currentRental !==
        this.state.currentRental
      ) {
        if (this.state.edit) {
          return;
        }
        this.setState({
          currentRental: nextProps.lockerRentalItemOptions.currentRental
        });

        // salva somente os ids das portas
        this.props.setCurrentLockerRentalItemOptionCompartments(
          nextProps.lockerRentalItemOptions.currentRental.compartments.map(
            i => i.compartment
          )
        );

        // salva registro completo do locker rental item option comp que possui o status do em uso
        this.props.setCurrentLockerRentalItemOptionCompartmentsFullData(
          nextProps.lockerRentalItemOptions.currentRental.compartments
        );
      }
    } catch (err) {}
  }

  handleChange = key => event => {
    this.setState({
      currentRental: {
        ...this.state.currentRental,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const { id } = this.props.match.params;
    const {
      updateLockerRentalItemOptionsRequest,
      lockerRentalItemOptions: { currentRentalCompartments }
    } = this.props;
    const {
      currentRental: {
        name,
        price,
        priceDay,
        maxRentalDays,
        status,
        maxRentalTime
      }
    } = this.state;

    this.setState({ edit: false });

    const dataUpdate = {
      name,
      price,
      priceDay,
      maxRentalDays,
      status,
      maxRentalTime,
      currentRentalCompartments: currentRentalCompartments.map(i => i.id)
    };

    updateLockerRentalItemOptionsRequest(id, dataUpdate);
  };

  render() {
    const {
      lockerRentalItemOptions,
      classes,
      setSnackbarVisibleLockerRentalItemOptions
    } = this.props;
    const { currentRental } = this.state;

    return (
      <Container title="Informações da locação">
        {lockerRentalItemOptions.loading ? (
          <Spinner />
        ) : (
          currentRental && (
            <ThemeTextFildGreen>
              <Snackbar
                message={lockerRentalItemOptions.snackbarMessage}
                open={lockerRentalItemOptions.snackbarVisible}
                variant={lockerRentalItemOptions.snackbarType}
                onClose={() => setSnackbarVisibleLockerRentalItemOptions(false)}
              />
              <EditorCurrentRegisterContainer
                disabled={lockerRentalItemOptions.loading || this.state.edit}
                flexDirection="row-reverse"
              >
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <ButtonEditorCurrentRegister
                    label="Editar"
                    disabled={
                      lockerRentalItemOptions.loading ||
                      this.state.edit ||
                      currentRental.finishedAt
                    }
                    onClick={() => {
                      this.setState({
                        edit: true
                      });
                      this.props.setEditableLockerRentalItemOption(true);
                    }}
                  />
                </Grid>
              </EditorCurrentRegisterContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="Nome"
                    className={classes.textField}
                    value={currentRental.name}
                    margin="normal"
                    variant="outlined"
                    disabled={!this.state.edit}
                    onChange={this.handleChange("name")}
                  />
                </Grid>

                {constants.lockerRentalItemOptionsEditColumns.map(column => (
                  <Grid
                    key={column.columnName}
                    item
                    xs={12}
                    md={2}
                    className="form-colaboradore-sm"
                  >
                    <TextField
                      id="outlined-name"
                      label={column.label}
                      className={classes.textField}
                      value={currentRental[column.columnName]}
                      margin="normal"
                      variant="outlined"
                      disabled={!this.state.edit}
                      type={column.type}
                      onChange={this.handleChange(column.columnName)}
                      InputProps={{
                        startAdornment: column.prefix && (
                          <InputAdornment position="start">
                            {column.prefix}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Data da criação"
                    className={classes.textField}
                    value={formatDate(currentRental.created_at)}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <LockerRentalItemOptionTableAssociation edit={this.state.edit} />

              {this.state.edit && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => this.handleSave()}
                    disabled={lockerRentalItemOptions.loading}
                    className={classes.button}
                  >
                    <DoneAll className={classes.leftIcon} />
                    salvar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        edit: false,
                        currentRental: lockerRentalItemOptions.currentRental
                      });

                      this.props.setEditableLockerRentalItemOption(false);

                      this.props.setCurrentLockerRentalItemOptionCompartments(
                        lockerRentalItemOptions.currentRental.compartments.map(
                          i => i.compartment
                        )
                      );
                    }}
                    disabled={lockerRentalItemOptions.loading}
                    className={classes.button}
                  >
                    <CancelIcon className={classes.leftIcon} />
                    Cancelar
                  </Button>
                </>
              )}
            </ThemeTextFildGreen>
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockerRentalItemOptions: state.lockerRentalItemOptions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerRentalItemOptionsActions,
      ...LockerAcions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(LockerRentalItemOptionEdit));

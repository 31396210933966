import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../routes/history";

import { Actions as CompartmentActions } from "../ducks/compartment";
import { Actions as SubTeamSubUnitsActions } from "../ducks/subTeamSubUnits";

export function* searchCompartmentByNumber(action) {
  try {
    const response = yield call(
      api.get,
      "/v1/adm/business/search/compartments",
      {
        params: {
          ...action.payload.data
        }
      }
    );

    yield put(CompartmentActions.compartmentSearchSuccess(response.data));
  } catch (err) {
    yield put(CompartmentActions.compartmentSearchError(err));
  }
}

export function* openCompartment(action) {
  try {
    const response = yield call(
      api.post,
      `/v1/adm/business/compartment/${action.payload.id}/open`
    );

    yield put(CompartmentActions.openCompartmentSuccess(response.data));
  } catch (err) {
    yield put(CompartmentActions.openCompartmentError(err));
  }
}

export function* getCompartments(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/compartments?${action.payload.data}`
    );

    yield put(CompartmentActions.getCompartmentsSuccess(response.data));
  } catch (err) {
    yield put(CompartmentActions.getCompartmentsError(err));
  }
}

export function* getCompartmentsOfTeamRequest(action) {
  try {
    const lockers = yield call(
      api.get,
      `/v1/adm/lockers?${action.payload.data}`
    );

    const idLocker = lockers.data.data[0].id || "";

    const response = yield call(
      api.get,
      `/v1/adm/compartments?${action.payload.data}&idLocker=${idLocker}`
    );

    yield put(CompartmentActions.getCompartmentsOfTeamSuccess(response.data));
  } catch (err) {
    yield put(CompartmentActions.getCompartmentsOfTeamError(err));
  }
}

export function* showCompartment(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/compartments/${action.payload.id}`
    );
    const info = yield call(api.get, "/v1/adm/compartments/info");

    yield put(CompartmentActions.showCompartmentSuccess(response.data));
    yield put(CompartmentActions.getCompartmentsInfoSuccess(info.data));
  } catch (err) {
    yield put(CompartmentActions.showCompartmentError(err));
  }
}

export function* updateCompartment(action) {
  try {
    const { currentSubTeamSubUnitsCompartment, ...data } = action.payload.data;

    yield call(api.put, `/v1/adm/compartments/${data.id}`, {
      ...data
    });

    if (currentSubTeamSubUnitsCompartment) {
      yield call(api.post, "/v1/adm/subTeamSubUnitsCompartment/lote", {
        idCompartment: data.id,
        data: currentSubTeamSubUnitsCompartment
      });

      const updatedSubTeamSubUnitsCompartment = yield call(
        api.get,

        `/v1/adm/subTeamSubUnitsCompartment/${data.id}&perPage=1000`
      );

      yield put(
        SubTeamSubUnitsActions.getSubTeamSubUnitsByCompartmentSuccess(
          updatedSubTeamSubUnitsCompartment.data.data
        )
      );
    }

    yield put(
      CompartmentActions.updateCompartmentSuccess({
        ...data
      })
    );
  } catch (err) {
    yield put(CompartmentActions.updateCompartmentError(err.response.data));
  }
}

export function* getCompartmentsInfo(action) {
  try {
    const info = yield call(api.get, "/v1/adm/compartments/info");

    yield put(CompartmentActions.getCompartmentsInfoSuccess(info.data));
  } catch (err) {
    yield put(CompartmentActions.getCompartmentsInfoError(err.response.data));
  }
}

export function* createCompartment(action) {
  try {
    const response = yield call(api.post, "/v1/adm/compartments", {
      ...action.payload.data
    });

    yield put(CompartmentActions.createCompartmentSuccess(response.data));
    history.push("/compartments");
  } catch (err) {
    yield put(CompartmentActions.createCompartmentError(err.response.data));
  }
}

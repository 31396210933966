import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AppBar from "../AppBar";

import { Actions as TeamsActions } from "../../store/ducks/teams";
import { materialStyle } from "../../styles";

class Container extends Component {
  componentWillMount() {
    const { setLocalTeam, teamsRequest, setCurrentTeamRequest } = this.props;
    setLocalTeam();
    teamsRequest("perPage=1000");
    setCurrentTeamRequest();
  }
  render() {
    const { children } = this.props;
    return <AppBar title={""}>{children}</AppBar>;
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeamsActions
    },
    dispatch
  );
export default connect(
  null,
  mapDispatchToProps
)(withStyles(materialStyle)(Container));

import React, { Component } from "react";

// Global
import Container from "../../components/Container";
import { materialStyle } from "../../styles";
import Spinner from "../../components/Spinner";

// Material
import {
  TextField,
  Grid,
  Button,
  InputAdornment,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "../../components/Snackbar";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerRentalItemOptionsActions } from "../../store/ducks/lockerRentalItemOptions";
import { Actions as LockerAcions } from "../../store/ducks/locker";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import { Cancel as CancelIcon, DoneAll } from "@material-ui/icons";
import { constants } from "../../config";
import { withTranslation } from "react-i18next";
import history from "../../routes/history";

class LockerRentalItemOptionCreate extends Component {
  state = {
    currentRental: {
      ...constants.lockerRentalItemOptionsEditColumns
        .map(i => {
          return { [i.columnName]: 0 };
        })
        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
      name: "",
      status: 1
    },
    isChanged: true,
    parameters: null,
    image: "",
    open: false,
    downloadFile: false,
    idDeliveryUpdateInfoSubType: null,

    currentCompartments: []
  };

  t = this.props.t;

  componentWillMount() {
    const { resetLockers, lockersRequest } = this.props;

    resetLockers();

    lockersRequest(`perPage=1000&getCompartments=true`);
  }

  handleChange = key => event => {
    this.setState({
      currentRental: {
        ...this.state.currentRental,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const {
      createLockerRentalItemOptionsRequest,
      setSnackbarLockerRentalItemOptions,
      lockerRentalItemOptions: { currentRentalCompartments }
    } = this.props;
    const {
      currentRental: {
        name,
        price,
        priceDay,
        maxRentalDays,
        status,
        maxRentalTime
      }
    } = this.state;

    const dataCreate = {
      name,
      price,
      priceDay,
      maxRentalDays,
      status,
      maxRentalTime,
      currentRentalCompartments: currentRentalCompartments.map(i => i.id)
    };

    if (!name || !price || !priceDay || !maxRentalDays || !maxRentalTime) {
      setSnackbarLockerRentalItemOptions({
        snackbarVisible: true,
        snackbarType: "warning",
        snackbarMessage: "Existem campos não preenchidos"
      });

      return;
    }

    createLockerRentalItemOptionsRequest(dataCreate);
  };

  render() {
    const {
      lockerRentalItemOptions,
      classes,
      setSnackbarVisibleLockerRentalItemOptions
    } = this.props;
    const { currentRental } = this.state;

    return (
      <Container title="Criar novo produto">
        {lockerRentalItemOptions.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <Snackbar
              message={lockerRentalItemOptions.snackbarMessage}
              open={lockerRentalItemOptions.snackbarVisible}
              variant={lockerRentalItemOptions.snackbarType}
              onClose={() => setSnackbarVisibleLockerRentalItemOptions(false)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="outlined-name"
                  label="Nome"
                  className={classes.textField}
                  value={currentRental.name}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("name")}
                />
              </Grid>

              {constants.lockerRentalItemOptionsEditColumns.map(column => (
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={column.label}
                    className={classes.textField}
                    value={currentRental[column.columnName]}
                    margin="normal"
                    variant="outlined"
                    type={column.type}
                    onChange={this.handleChange(column.columnName)}
                    InputProps={{
                      startAdornment: column.prefix && (
                        <InputAdornment position="start">
                          {column.prefix}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              ))}

              <Grid item xs={12} md={2} className="form-colaboradore-sm">
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label={"Status"}
                  className={classes.selectType}
                  value={currentRental.status}
                  onChange={this.handleChange("status")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <MenuItem key={1} value={1}>
                    {this.t("common:enabled")}
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    {this.t("common:disabled")}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              onClick={() => this.handleSave()}
              className={classes.button}
            >
              <DoneAll className={classes.leftIcon} />
              salvar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                history.push("/rentalItems/locker/items");
              }}
              className={classes.button}
            >
              <CancelIcon className={classes.leftIcon} />
              Cancelar
            </Button>
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockerRentalItemOptions: state.lockerRentalItemOptions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerRentalItemOptionsActions,
      ...LockerAcions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation("delivery")(
    withStyles(materialStyle)(LockerRentalItemOptionCreate)
  )
);

import React, { Component } from "react";
import PropTypes from "prop-types";

// Global Components
import Container from "../../components/Container";
import UserTable from "./components/UserTable";
import MaskedInput from "react-text-mask";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

/**
 * Redux & Saga
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as UserActions } from "../../store/ducks/users";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import { getOnlyNumbers } from "../../services";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textFieldMasked: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 16
  },
  progress: {
    margin: theme.spacing(2)
  }
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
      // showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

class Users extends Component {
  state = {
    email: "",
    mobile: "",
    cpf: ""
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSearch = () => {
    const { searchEmailRequest } = this.props;
    const { email, mobile, cpf } = this.state;

    if (!email && !mobile && !cpf) {
      return;
    }

    searchEmailRequest({
      email,
      mobile: getOnlyNumbers(mobile),
      cpf: getOnlyNumbers(cpf)
    });
  };

  render() {
    const { classes, history } = this.props;

    return (
      <Container title="Usuários">
        <h3>Criar um usuário</h3>
        <Button
          onClick={() => history.push("/users/create")}
          variant="contained"
        >
          Criar
        </Button>

        <div>
          <TextField
            id="outlined-name"
            label="email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange("email")}
            margin="normal"
            variant="outlined"
          />
          <TextField
            variant="outlined"
            className={classes.textFieldMasked}
            label="Celular"
            value={this.state.mobile}
            onChange={this.handleChange("mobile")}
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: TextMaskCustom
            }}
          />
          <TextField
            variant="outlined"
            className={classes.textFieldMasked}
            label="CPF"
            value={this.state.cpf}
            onChange={this.handleChange("cpf")}
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: TextMaskCustomCPF
            }}
          />
        </div>
        <Button
          onClick={this.handleSearch}
          style={{ background: "#4AC151", color: "#fff" }}
          variant="contained"
        >
          Pesquisar
        </Button>
        <UserTable history={history} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Users));

import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

// Material
import { TextField, CircularProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import SubTeamsApartmentTable from "../../components/SubTeamsCondTable";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";
import getOnlyNumbers from "../../services/getOnlyNumbers";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { withTranslation } from "react-i18next";
import FilterTitle from "../../components/FilterTitle";
import Spinner from "../../components/Spinner";
import ButtonClick from "../../components/ButtonClick";

class SubTeams extends Component {
  static propTypes = {
    getSubTeamsRequest: PropTypes.func.isRequired
  };

  t = this.props.t;

  state = {
    name: "",
    cnpj: "",
    idSubTeam: JSON.parse(localStorage.getItem("auth")).user.idSubTeam
  };

  componentWillMount() {
    this.handleInitSearch();
  }

  handleInitSearch = () => {
    const { getSubTeamsRequest } = this.props;
    const { idSubTeam } = this.state;

    if (idSubTeam) {
      getSubTeamsRequest(`perPage=20&idSubTeam=${idSubTeam}`);
    } else {
      getSubTeamsRequest("perPage=20");
    }
  };

  handleChange = name => event => {
    let value = event.target.value;

    if (name === "cnpj") {
      value = getOnlyNumbers(value);
    }

    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { getSubTeamsRequest } = this.props;
    const { name, cnpj } = this.state;

    let query = queryString.stringify({
      name,
      cnpj
    });

    getSubTeamsRequest(`perPage=20&${query}`);
  };

  render() {
    const { classes, history, subTeams, setSnackbarVisible } = this.props;

    return (
      <Container>
        {subTeams.loading ? (
          <Spinner />
        ) : (
          <>
            <Snackbar
              open={subTeams.snackbarVisible}
              variant="warning"
              onClose={setSnackbarVisible}
              message={this.t("common:not_found_user")} //"Usuário não encontrado"
            />
            <FilterTitle />
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  label={this.t("common:name")}
                  className={classes.textField1}
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <TextField
                  label={this.t("common:company_registry")}
                  className={classes.textField1}
                  value={this.state.cnpj}
                  onChange={this.handleChange("cnpj")}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustomCNPJ
                  }}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <ButtonClick
                  className={classes.button}
                  onClick={this.handleSearch}
                  variant="contained"
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    height: 35
                  }}
                  disabled={
                    subTeams.loading || (!this.state.name && !this.state.cnpj)
                  }
                >
                  {subTeams.loading ? (
                    <CircularProgress />
                  ) : (
                    this.t("common:search")
                  )}
                </ButtonClick>
                <ButtonClick
                  className={classes.button}
                  onClick={this.handleInitSearch}
                  variant="contained"
                  disabled={!this.state.name && !this.state.cnpj}
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    height: 35
                  }}
                >
                  {this.t("common:search_clear")}
                </ButtonClick>
              </Grid>
            </Grid>
            <SubTeamsApartmentTable history={history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeams: state.subTeams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(SubTeamActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("condominium")(withStyles(materialStyle)(SubTeams)));

export const Types = {
  resetUsers: "reset/Users",

  searchEmailRequest: "searchEmail/Request",
  searchEmailSuccess: "searchEmail/Success",
  searchEmailError: "searchEmail/Error",

  searchUserRequest: "searchUser/Request",
  searchUserSuccess: "searchUser/Success",
  searchUserError: "searchUser/Error",

  updateUserRequest: "updateUser/Request",
  updateUserSuccess: "updateUser/Success",
  updateUserError: "updateUser/Error",

  createUserRequest: "createUser/Request",
  createUserSuccess: "createUser/Success",
  createUserError: "createUser/Error",

  setSnackbar: "setSnackbar"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  searchData: null,
  snackbar: false
};

export default function users(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.searchEmailRequest:
      return {
        ...INITAL_STATE,
        loading: true
      };

    case Types.searchEmailSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.searchEmailError:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        searchData: null
      };

    case Types.searchUserRequest:
      return {
        ...state,
        loading: true,
        error: null,
        searchData: null
      };

    case Types.searchUserSuccess:
      return {
        ...state,
        loading: false,
        searchData: action.payload.data
      };

    case Types.searchUserError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.resetUsers:
      return INITAL_STATE;

    case Types.updateUserRequest:
      return {
        ...state,
        loading: true,
        error: null,
        searchData: {
          ...state.searchData,
          ...action.payload.data
        }
      };

    case Types.updateUserSuccess:
      return {
        ...state,
        loading: false,
        snackbar: true,
        searchData: {
          ...state.searchData,
          ...action.payload.data
        }
      };

    case Types.updateUserError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.setSnackbar:
      return {
        ...state,
        snackbar: !state.snackbar
      };

    case Types.createUserRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.createUserSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createUserError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  searchEmailRequest: data => ({
    type: Types.searchEmailRequest,
    payload: { data }
  }),

  searchEmailSuccess: data => ({
    type: Types.searchEmailSuccess,
    payload: { data }
  }),

  searchEmailError: error => ({
    type: Types.searchEmailError,
    payload: { error }
  }),

  searchUserRequest: id => ({
    type: Types.searchUserRequest,
    payload: { id }
  }),

  searchUserSuccess: data => ({
    type: Types.searchUserSuccess,
    payload: { data }
  }),

  searchUserError: error => ({
    type: Types.searchUserError,
    payload: { error }
  }),

  updateUserRequest: (id, data) => ({
    type: Types.updateUserRequest,
    payload: { id, data }
  }),

  updateUserSuccess: data => ({
    type: Types.updateUserSuccess,
    payload: { data }
  }),

  updateUserError: error => ({
    type: Types.updateUserError,
    payload: { error }
  }),

  setSnackbar: () => ({
    type: Types.setSnackbar
  }),

  createUserRequest: data => ({
    type: Types.createUserRequest,
    payload: { data }
  }),

  createUserSuccess: data => ({
    type: Types.createUserSuccess,
    payload: { data }
  }),

  createUserError: error => ({
    type: Types.createUserError,
    payload: { error }
  })
};

import React, { Component } from "react";

// Global
import Container from "../../../../components/Container";
import { materialStyle } from "../../../../styles";
import Spinner from "../../../../components/Spinner";
import { withTranslation } from "react-i18next";
import Snackbar from "../../../../components/Snackbar";

// Material
import {
  TextField,
  Button,
  Grid,
  Dialog,
  InputAdornment
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RentalItemsActions } from "../../../../store/ducks/rentalItems";

import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import formatPrice from "../../../../utils/formatPrice";

class RentalItemDeliveryItemEdit extends Component {
  t = this.props.t;
  state = {
    currentRental: null,
    isChanged: true,
    parameters: null,
    image: "",
    open: false,
    downloadFile: false,
    idDeliveryUpdateInfoSubType: null
  };

  componentWillMount() {
    const { showRentalItemDeliveryItemsRequest } = this.props;
    const { id } = this.props.match.params;

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;
    const { email } = JSON.parse(localStorage.getItem("auth")).user;

    this.setState({ parameters, email });

    showRentalItemDeliveryItemsRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rentalItem.currentRental !== this.state.currentRental) {
      this.setState({
        currentRental: nextProps.rentalItem.currentRental
      });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      isChanged: false,
      currentRental: {
        ...this.state.currentRental,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const { updateDeliveryRequest } = this.props;

    const { currentRental } = this.state;

    const tmp = currentRental;

    if (tmp.idBusiness === "select") {
      tmp.idBusiness = "";
    }

    if (tmp.idBusinessColector === "select") {
      tmp.idBusinessColector = "";
    }

    updateDeliveryRequest(tmp);
  };

  render() {
    const { rentalItem, classes, setSnackbarVisibleDelivery } = this.props;
    const { currentRental } = this.state;

    return (
      <Container title={this.t("editTitle")}>
        <Snackbar
          message={rentalItem.snackbarMessage}
          open={rentalItem.snackbarVisible}
          variant={rentalItem.snackbarType}
          onClose={() => setSnackbarVisibleDelivery(false)}
        />
        {rentalItem.loading || this.state.downloadFile ? (
          <Spinner />
        ) : (
          currentRental && (
            <ThemeTextFildGreen>
              <Dialog
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
                hasCloseButton
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              >
                <img
                  style={{ width: "auto", height: "100%" }}
                  src={this.state.image}
                  alt="Protocolo"
                />
              </Dialog>
              <h5 className={classes.subTitle}>Informações da entrega</h5>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="Nome do produto"
                    className={classes.textField}
                    value={currentRental.item.name}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange("customerReferenceId")}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t("common:locker")}
                    className={classes.textField}
                    value={`${currentRental.locker.lockerNumber} - ${
                      currentRental.locker.address
                    }`}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={"Custo total"}
                    className={classes.textField}
                    value={formatPrice(currentRental.cost)}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={"Status"}
                    className={classes.textField}
                    value={currentRental.status.description}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t("createdAt")}
                    className={classes.textField}
                    value={currentRental.created_at}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                // onClick={this.handleSave}
                disabled={rentalItem.loading}
                className={classes.button}
              >
                <SaveIcon className={classes.leftIcon} />
                Salvar
              </Button>
            </ThemeTextFildGreen>
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  rentalItem: state.rentalItem
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RentalItemsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(materialStyle)(
    withTranslation("delivery")(RentalItemDeliveryItemEdit)
  )
);

export const Types = {
  setSnackbarVisibleRental: "setSnackbarVisible/Delviery",

  getRentalRequest: "getRental/Request",
  getRentalSuccess: "getRental/Success",
  getRentalError: "getRental/Error",

  getRentalItemDeliveryItemsRequest: "getRentalItemDeliveryItems/Request",
  getRentalItemDeliveryItemsSuccess: "getRentalItemDeliveryItems/Success",
  getRentalItemDeliveryItemsError: "getRentalItemDeliveryItems/Error",

  showRentalItemDeliveryItemsRequest: "showRentalItemDeliveryItems/Request",
  showRentalItemDeliveryItemsSuccess: "showRentalItemDeliveryItems/Success",
  showRentalItemDeliveryItemsError: "showRentalItemDeliveryItems/Error",

  showRentalRequest: "showRental/Request",
  showRentalSuccess: "showRental/Success",
  showRentalError: "showRental/Error",

  updateRentalRequest: "updateRental/Request",
  updateRentalSuccess: "updateRental/Success",
  updateRentalError: "updateRental/Error",

  cancelRentalRequest: "cancelRental/Request",
  cancelRentalSuccess: "cancelRental/Success",
  cancelRentalError: "cancelRental/Error",

  resetRentalState: "resetRentalState",
  setSnackbar: "setSnackbar/Rental"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  currentRental: null,
  snackbarVisible: false,
  snackbarType: "success",
  snackbarMessage: "",
  total: null,
  perPage: null,
  lastPage: null,
  page: null,
  setEditable: false
};

export default function RentalItems(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.resetRentalState:
      return INITAL_STATE;

    case Types.setSnackbarVisibleRental:
      return {
        ...state,
        snackbarVisible: action.payload.status,
        error: null
      };

    case Types.setEditableRental:
      return {
        ...state,
        setEditable: action.payload.status
      };

    case Types.getRentalRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getRentalSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getRentalItemDeliveryItemsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getRentalItemDeliveryItemsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getRentalItemDeliveryItemsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showRentalItemDeliveryItemsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.showRentalItemDeliveryItemsSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data
      };

    case Types.showRentalItemDeliveryItemsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showRentalRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentRental: null
      };

    case Types.showRentalSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data
      };

    case Types.showRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentRental: null
      };

    case Types.createRentalRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.createRentalSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true
      };

    case Types.searchRentalRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.searchRentalSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
        data: action.payload.data.data
      };

    case Types.searchRentalError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateRentalRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.updateRentalSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data,
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: "Atualizado!"
      };

    case Types.updateRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: "Não foi possível atualizar"
      };

    case Types.openRentalLockerRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
        snackbarVisible: INITAL_STATE.snackbarVisible
      };

    case Types.openRentalLockerSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.openRentalLockerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.sendRentalEmailCodominiumRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.sendRentalEmailCodominiumSuccess:
      return {
        ...state,
        loading: false,
        snackbarMessage: "E-mail enviado!",
        snackbarType: "success",
        snackbarVisible: true
      };

    case Types.sendRentalEmailCodominiumError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.cancelRentalRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.cancelRentalSuccess:
      return {
        ...state,
        loading: false,
        snackbarMessage: "Entrega finalizada!",
        snackbarType: "success",
        snackbarVisible: true,
        setEditable: false,
        currentRental: {
          ...state.currentRental,
          ...action.payload.data
        },
        data: state.data.map(
          item =>
            item.id === action.payload.data.id
              ? { ...item, ...action.payload.data }
              : item
        )
      };

    case Types.cancelRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.setSnackbar:
      return {
        ...state,
        ...action.payload.data
      };

    default:
      return state;
  }
}

export const Actions = {
  setSnackbarVisibleRental: status => ({
    type: Types.setSnackbarVisibleRental,
    payload: { status }
  }),

  getRentalRequest: data => ({
    type: Types.getRentalRequest,
    payload: { data }
  }),

  getRentalSuccess: data => ({
    type: Types.getRentalSuccess,
    payload: { data }
  }),

  getRentalError: error => ({
    type: Types.getRentalError,
    payload: { error }
  }),

  getRentalItemDeliveryItemsRequest: data => ({
    type: Types.getRentalItemDeliveryItemsRequest,
    payload: { data }
  }),

  getRentalItemDeliveryItemsSuccess: data => ({
    type: Types.getRentalItemDeliveryItemsSuccess,
    payload: { data }
  }),

  getRentalItemDeliveryItemsError: error => ({
    type: Types.getRentalItemDeliveryItemsError,
    payload: { error }
  }),

  showRentalItemDeliveryItemsRequest: id => ({
    type: Types.showRentalItemDeliveryItemsRequest,
    payload: { id }
  }),

  showRentalItemDeliveryItemsSuccess: data => ({
    type: Types.showRentalItemDeliveryItemsSuccess,
    payload: { data }
  }),

  showRentalItemDeliveryItemsError: error => ({
    type: Types.showRentalItemDeliveryItemsError,
    payload: { error }
  }),

  showRentalRequest: id => ({
    type: Types.showRentalRequest,
    payload: { id }
  }),

  showRentalSuccess: data => ({
    type: Types.showRentalSuccess,
    payload: { data }
  }),

  showRentalError: error => ({
    type: Types.showRentalError,
    payload: { error }
  }),

  createRentalRequest: data => ({
    type: Types.createRentalRequest,
    payload: { data }
  }),

  createRentalSuccess: data => ({
    type: Types.createRentalSuccess,
    payload: { data }
  }),

  createRentalError: error => ({
    type: Types.createRentalError,
    payload: { error }
  }),

  searchRentalRequest: data => ({
    type: Types.searchRentalRequest,
    payload: { data }
  }),

  searchRentalSuccess: data => ({
    type: Types.searchRentalSuccess,
    payload: { data }
  }),

  searchRentalError: error => ({
    type: Types.searchRentalError,
    payload: { error }
  }),

  updateRentalRequest: id => ({
    type: Types.updateRentalRequest,
    payload: { id }
  }),

  updateRentalSuccess: data => ({
    type: Types.updateRentalSuccess,
    payload: { data }
  }),

  updateRentalError: data => ({
    type: Types.updateRentalError,
    payload: { data }
  }),

  openRentalLockerRequest: data => ({
    type: Types.openRentalLockerRequest,
    payload: { data }
  }),

  openRentalLockerSuccess: data => ({
    type: Types.openRentalLockerSuccess,
    payload: { data }
  }),

  openRentalLockerError: error => ({
    type: Types.openRentalLockerError,
    payload: { error }
  }),

  setEditableRental: status => ({
    type: Types.setEditableRental,
    payload: { status }
  }),

  sendRentalEmailCodominiumRequest: idRental => ({
    type: Types.sendRentalEmailCodominiumRequest,
    payload: { idRental }
  }),

  sendRentalEmailCodominiumSuccess: () => ({
    type: Types.sendRentalEmailCodominiumSuccess
  }),

  sendRentalEmailCodominiumError: error => ({
    type: Types.sendRentalEmailCodominiumError,
    payload: { error }
  }),

  cancelRentalRequest: (idRental, idRentalUpdateInfoSubType) => ({
    type: Types.cancelRentalRequest,
    payload: { idRental, idRentalUpdateInfoSubType }
  }),

  cancelRentalSuccess: data => ({
    type: Types.cancelRentalSuccess,
    payload: { data }
  }),

  cancelRentalError: error => ({
    type: Types.cancelRentalError,
    payload: { error }
  }),

  resetRentalState: () => ({
    type: Types.resetRentalState
  }),

  setSnackbar: data => ({
    type: Types.setSnackbar,
    payload: { data }
  })
};

import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as RentalItemsActions } from "../ducks/rentalItems";

const URL = "/v1/adm/rentalItems/delivery/items";

export function* getRentalItemDeliveryItemsRequest(action) {
  try {
    const response = yield call(api.get, `${URL}?${action.payload.data}`);

    yield put(
      RentalItemsActions.getRentalItemDeliveryItemsSuccess(response.data)
    );
  } catch (err) {
    yield put(RentalItemsActions.getRentalItemDeliveryItemsError(err));
  }
}

export function* showRentalItemDeliveryItemsRequest(action) {
  try {
    const response = yield call(api.get, `${URL}/${action.payload.id}`);

    yield put(
      RentalItemsActions.showRentalItemDeliveryItemsSuccess(response.data)
    );
  } catch (err) {
    yield put(RentalItemsActions.showRentalItemDeliveryItemsError(err));
  }
}

export function* updateRentalItemDeliveryItemsRequest(action) {
  try {
    yield call(api.put, `${URL}/${action.payload.id}`);
    const response = yield call(api.get, `${URL}/${action.payload.id}`);

    yield put(
      RentalItemsActions.updateRentalItemDeliveryItemsSuccess(response.data)
    );
  } catch (err) {
    yield put(RentalItemsActions.updateRentalItemDeliveryItemsError(err));
  }
}

import i18next from "i18next";
import { colors } from "../styles";

export default {
  cookies: {
    authExpirationMinutes: new Date(new Date().getTime() + 60 * 60 * 1000),
    user: "user"
  },

  // appFunctions: [
  //   { id: '4', text: 'Nenhuma', permissions: '*' },
  //   { id: '1', text: 'Conta para entrega', permissions: '*' },
  //   { id: '2', text: 'Conta para coleta', permissions: '*' },
  //   { id: '3', text: 'Mista', permissions: '*' },
  // ],

  appFunctions: () => [
    { id: "4", text: i18next.t("common:none"), permissions: "*" },
    { id: "1", text: i18next.t("appRoles:colectAccount"), permissions: "*" },
    { id: "2", text: i18next.t("appRoles:deliveryAccount"), permissions: "*" },
    { id: "3", text: i18next.t("appRoles:mixed"), permissions: "*" }
  ],

  deliveryStatus: {
    all: {
      id: "all",
      slug: "all",
      label: "Todos",
      visible: true
    },
    active: {
      id: "active",
      slug: "active",
      label: "delivery_status:active_deliveries",
      visible: true
    },
    created: {
      label: "Entrega criada",
      slug: "created",
      id: "0",
      visible: false
    },
    deposit: {
      label: "delivery_status:waiting_to_deposit",
      slug: "open",
      id: "1",
      visible: true
    },
    collect: {
      label: "delivery_status:waiting_to_collect", //"Aguardando coleta",
      slug: "collect",
      id: "2",
      visible: true
    },
    finished: {
      label: "delivery_status:finished",
      slug: "finished",
      id: "3",
      visible: true
    },
    finishedByAdm: {
      label: "delivery_status:finished_by_manager",
      slug: "finished",
      id: "7",
      visible: true
    },
    reloadCreated: {
      label: "delivery_status:waiting_to_return",
      slug: "inReaload",
      id: "4",
      visible: true
    },
    reloaded: {
      label: "delivery_status:returned_delivery",
      slug: "reloaded",
      id: "5",
      visible: true
    },
    canceled: {
      label: "delivery_status:canceled",
      slug: "canceled",
      id: "6",
      visible: true
    }
  },

  deliveryStatusLaundry: {
    all: { id: "all", slug: "all", label: "Todos", visible: true },
    active: {
      id: "active",
      slug: "active",
      label: "Todas as ativas",
      visible: true
    },
    created: {
      label: "Entrega criada",
      slug: "created",
      id: "0",
      visible: false
    },
    deposit: {
      label: "Novo pedido",
      slug: "open",
      id: "1",
      visible: true
    },
    collect: {
      label: "Lavando roupas",
      slug: "collect",
      id: "2",
      visible: true
    },
    finished: { label: "Finalizada", slug: "finished", id: "3", visible: true },
    finishedByAdm: {
      label: "Finalizada pelo gestor",
      slug: "finished",
      id: "7",
      visible: true
    },
    reloadCreated: {
      label: "Aguardando cliente retirar",
      slug: "inReaload",
      id: "4",
      visible: true
    },
    reloaded: {
      label: "Pedido retornado",
      slug: "reloaded",
      id: "5",
      visible: true
    },
    canceled: {
      label: "Cancelado",
      slug: "canceled",
      id: "6",
      visible: true
    }
  },

  deliveryTableHeaders: {
    id: "entrega",
    user: "usuário",
    createdAt: "data de criação",
    lockerAddress: "locker",
    compartmentNumber: "porta",
    status: "status",
    client: "cliente",
    finished: "finalizada",
    secretCode: "código de acesso",
    note: "experiência do cliente",

    // subTeam*
    subTeam: "estabelecimento",
    subTeamCondominium: "condomínio",
    subTeamUnitsCondominium: "bloco",
    subTeamSubUnitsCondominium: "unidade",
    subTeamSubUnitsContactCondominium: "morador"
  },

  tableHeaders: {
    customerLogDescription: "descrição",
    customerLogsAbbreviation: "código do log",
    customerLogsDelivery: "entrega",
    customerLogsSubTeam: "condomínio",
    customerLogsSubTeamUnits: "bloco",
    customerLogsSubTeamSubUnits: "unidade",
    customerLogsSubTeamSubUnitsContact: "morador"
  },

  deliveryStatusTable: () => [
    "",
    i18next.t("delivery_status:waiting_to_deposit"), // 1
    i18next.t("delivery_status:waiting_to_collect"), // 2
    i18next.t("delivery_status:finished"), // 3
    i18next.t("delivery_status:waiting_to_return"), // 4
    i18next.t("delivery_status:returned_delivery"), // 5
    i18next.t("delivery_status:canceled"), // 6
    i18next.t("delivery_status:finished_by_manager"), // 7
    i18next.t("delivery_status:canceled")
  ],

  deliveryStatusLaundryTable: [
    "",
    "Novo pedido",
    "Lavando roupas",
    "Finalizada",
    "Aguardando cliente retirar",
    "Pedido retornado",
    "Cancelada",
    "Finalizada pelo gestor"
  ],

  toastVariants: {
    success: "success",
    error: "error",
    warning: "warning"
  },

  optionPerPage: [5, 10, 20, 50, 100, 200, 300, 1000],

  snackbarVariants: {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning"
  },

  snackbarMessages: {
    invalidMobileNumber: "Número de celular inválido",
    invalidPassword: "Senha inválida",
    invalidPasswordConfirmation: "Confirmação senha inválida"
  },

  tableActionOptions: () => {
    return {
      show: {
        id: Math.random(),
        label: i18next.t("table_actions:view"),
        onlyActive: false
      },
      edit: {
        id: Math.random(),
        label: i18next.t("table_actions:edit"),
        onlyActive: false
      },
      cancel: {
        id: Math.random(),
        label: i18next.t("table_actions:finish"),
        onlyActive: true
      }
    };
  },

  ticketURL:
    "https://api.whatsapp.com/send?phone=551132307250&text=Oi!%20Vim%20do%20Painel%20de%20Gest%C3%A3o%20Handover%20e%20preciso%20de%20suporte.",
  baseConhecimento: "https://handover.tomticket.com/kb",

  lockerStatusOptions: [
    {
      id: "active",
      label: "Ativo",
      value: 1
    },
    {
      id: "inactive",
      label: "Desativado",
      value: 0
    },
    {
      id: "full",
      label: "Todos",
      value: "full"
    }
  ],

  buckets: {
    informacoedevistorias: "informacoedevistorias",
    fileOperations: "file-operations"
  },

  parameters: {
    general: [
      {
        key: "autoUpdateOnOpen",
        isText: false,
        label: "Atualiza entrega ao abrir porta?",
        subKeys: []
      },
      {
        key: "generateCustomerReferenceId",
        isText: false,
        label: "Gera número de entrega automaticamente?",
        subKeys: []
      },
      {
        key: "textInputCustomerReferenceId",
        isText: true,
        label: "Texto número de entrega",
        subKeys: []
      },
      {
        key: "useSecretCode",
        isText: false,
        label: "Usa código de acesso?",
        subKeys: []
      },
      {
        key: "textSecretCode",
        isText: true,
        label: "Título do código de acesso",
        subKeys: []
      },
      {
        key: "secretCodePlaceholder",
        isText: true,
        label: "Marca d`agua do codigo de acesso",
        subKeys: []
      },

      {
        key: "useCustomerName",
        isText: false,
        label: "Usa nome de cliente?",
        subKeys: []
      },

      {
        key: "useCustomerLastName",
        isText: false,
        label: "Usa sobrenome de cliente?",
        subKeys: []
      },

      {
        key: "useCustomerEmail",
        isText: false,
        label: "Usa email de cliente?",
        subKeys: []
      }
    ],

    notifications: [
      {
        key: "sendSMSToCollect",
        isText: false,
        label: "Envia SMS?",
        subKeys: []
      },
      {
        key: "useWhatsapp",
        isText: false,
        label: "Envia whatsapp?",
        subKeys: []
      }
    ],

    laundryParams: [
      {
        key: "laundryEmailsToNotification",
        isText: true,
        label: "Emails para notificação",
        subKeys: []
      }
    ]
  },

  integrations: [
    {
      key: "usePartnerWinker",
      label: "Winker",
      subKeys: [
        {
          key: "winkerToken",
          label: "Winker Token"
        },
        {
          key: "winkerCookie",
          label: "Winker Cookie"
        }
      ]
    },
    {
      key: "usePartnerScond",
      label: "Scond",
      subKeys: [
        {
          key: "scondUsername",
          label: "Usuário"
        },
        {
          key: "scondPassword",
          label: "Senha"
        },
        {
          key: "scondTenant",
          label: "Tenant"
        }
      ]
    },
    {
      key: "usePartnerOnePortaria",
      label: "One Portaria",
      subKeys: [
        {
          key: "partnerOnePortariaUrl",
          label: "URL"
        }
      ]
    },
    {
      key: "usePartnerIModulo",
      label: "IModulo",
      subKeys: []
    },
    {
      key: "usePartnerMyCond",
      label: "My Cond",
      subKeys: []
    },
    {
      key: "usePartnerSeuCondominio",
      label: "Seu Condomínio",
      subKeys: []
    },
    {
      key: "usePartnerECondos",
      label: "Econdos",
      subKeys: []
    }
  ],

  buttonDefaultStyle: {
    backgroundColor: "#006660",
    color: "#fff",
    textTransform: "lowercase",
    height: 35,
    fontSize: 15
  },

  buttonDefaultDisabledStyle: {
    backgroundColor: colors.disabled,
    color: "#fff",
    textTransform: "lowercase",
    height: 35,
    fontSize: 15
  },

  // itemOptionAll: {
  //   id: 'all',
  //   name: 'Todos',
  //   key: 'all',
  //   address: 'Todos',
  //   compartmentNumber: 'Todos',
  // },

  itemOptionAll: () => {
    return {
      id: "all",
      name: i18next.t("common:all"),
      key: "all",
      address: i18next.t("common:all"),
      compartmentNumber: i18next.t("common:all")
    };
  },
  rentalStatus: {
    all: { id: "all", slug: "all", label: "Todos", visible: true },
    active: {
      id: "active",
      slug: "active",
      label: "Ativas",
      visible: true
    },
    finished: {
      label: "Finalizadas",
      slug: "finished",
      id: "finished",
      visible: true
    }
  },

  whenCallOptions: [
    {
      id: "create",
      label: "Entrega recebida no locker",
      checked: false
    },
    {
      id: "create_collect",
      label: "Entrega retirada pelo morador",
      checked: false
    }
  ],

  // tokenActionOptions: [
  //   {
  //     id: 'contactEdit',
  //     key: 'contactEdit',
  //     label: 'Gestão dos moradores (criar, editar e desativar)',
  //     value: true,
  //   },
  //   // {
  //   //   id: "createDelivery",
  //   //   key: "createDelivery",
  //   //   label: "Criar entregas",
  //   //   value: false,
  //   //   activeAllOnSelect: false
  //   // }
  // ],
  tokenActionOptions: () => [
    {
      id: "contactEdit",
      key: "contactEdit",
      label: i18next.t("config:integrations.api_functions_opt1"),
      value: true
    }
    // {
    //   id: "createDelivery",
    //   key: "createDelivery",
    //   label: "Criar entregas",
    //   value: false,
    //   activeAllOnSelect: false
    // }
  ],

  oldIntegrationsKeys: [
    "usePartnerNepos",
    "usePartnerIModulo",
    "usePartnerMyCond",
    "usePartnerSeuCondominio",
    "usePartnerWinker",
    "usePartnerScond",
    "usePartnerOnePortaria",
    "usePartnerECondos"
  ],

  oldIntegrations: [
    { name: "Nepos", key: "usePartnerNepos" },
    { name: "IModulo", key: "usePartnerIModulo" },
    { name: "MyCond", key: "usePartnerMyCond" },
    { name: "SeuCondominio", key: "usePartnerSeuCondominio" },
    { name: "Winker", key: "usePartnerWinker" },
    { name: "Scond", key: "usePartnerScond" },
    { name: "OnePortaria", key: "usePartnerOnePortaria" },
    { name: "ECondos", key: "usePartnerECondos" }
  ],

  lockerRentalItemOptionsEditColumns: [
    {
      columnName: "price",
      label: "Preço por hora",
      type: "number",
      prefix: "R$"
    },
    {
      columnName: "priceDay",
      label: "Preço por dia",
      type: "number",
      prefix: "R$"
    },
    {
      columnName: "maxRentalDays",
      label: "Max dias de locação",
      type: "number",
      prefix: ""
    },
    {
      columnName: "maxRentalTime",
      label: "Máximos de horas de locação",
      type: "number",
      prefix: ""
    }
  ]
};

import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as LockerRentalItemOptionsActions } from "../ducks/lockerRentalItemOptions";
import history from "../../routes/history";

export function* getLockerRentalItemOptionsRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/rentalItems/locker/items?${action.payload.data}`
    );

    yield put(
      LockerRentalItemOptionsActions.getLockerRentalItemOptionsSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(
      LockerRentalItemOptionsActions.getLockerRentalItemOptionsError(err)
    );
  }
}

export function* showLockerRentalItemOptionsRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/rentalItems/locker/items/${action.payload.id}`
    );

    yield put(
      LockerRentalItemOptionsActions.showLockerRentalItemOptionsSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(
      LockerRentalItemOptionsActions.showLockerRentalItemOptionsError(err)
    );
  }
}

export function* updateLockerRentalItemOptionsRequest(action) {
  try {
    yield call(
      api.put,
      `/v1/adm/rentalItems/locker/items/${action.payload.id}`,
      action.payload.dataUpdate
    );

    const response = yield call(
      api.get,
      `/v1/adm/rentalItems/locker/items/${action.payload.id}`
    );

    yield put(
      LockerRentalItemOptionsActions.updateLockerRentalItemOptionsSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(
      LockerRentalItemOptionsActions.updateLockerRentalItemOptionsError(err)
    );
  }
}

export function* createLockerRentalItemOptionsRequest(action) {
  try {
    const response = yield call(
      api.post,
      `/v1/adm/rentalItems/locker/items`,
      action.payload.data
    );

    yield put(
      LockerRentalItemOptionsActions.createLockerRentalItemOptionsSuccess(
        response.data
      )
    );

    history.push(`/rentalItems/locker/items/${response.data.id}`);
  } catch (err) {
    yield put(
      LockerRentalItemOptionsActions.createLockerRentalItemOptionsError(err)
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Table, TableContainer, withWidth } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import SimpleActionButton from "../SimpleActionButton";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryActions } from "../../store/ducks/delivery";

// services
import checkHandoverEmail from "../../services/checkHandoverEmail";
import formatDate from "../../functions/formatDate";
import { constants } from "../../config";
import defaultConfig from "../../config/default";
import showCustomerReferenceIdOnTables from "../../functions/showCustomerReferenceIdOnTables";
import diffTime from "../../utils/diffTime";

import CustomDialogCancelDelivery from "../CustomDialogCancelDelivery";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 10,
    parameters: null,
    dashboardParams: null,
    actionOptions: [
      constants.tableActionOptions().show,
      constants.tableActionOptions().edit,
      constants.tableActionOptions().cancel
    ],
    openDialogCancel: false,
    currentDelivery: null,
    idDeliveryUpdateInfoSubType: null
  };

  t = this.props.t;

  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    const data = { parameters };

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      Object.assign(data, {
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    this.setState(data);
  }

  handleChangePage = (event, newPage) => {
    const { getDeliveriesRequest, search, perPage } = this.props;

    getDeliveriesRequest(
      `${search()}&page=${newPage + 1}&perPage=${perPage()}`
    );
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);
    const {
      onChangePerPage,
      search,
      getDeliveriesRequest,
      history
    } = this.props;

    onChangePerPage(rowsPerPage);

    const parsed = queryString.parse(search());
    delete parsed.perPage;

    const _search = `${queryString.stringify(parsed)}&perPage=${rowsPerPage}`;

    this.setState({ rowsPerPage });

    getDeliveriesRequest(_search);
    history.push(`/delivery?${_search}`);
  };
  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));
  checkIfExistsSubTeam = () => {
    const { delivery } = this.props;
    if (!delivery || !delivery.data || !delivery.data.length) return false;
    for (let i = 0; i < delivery.data.length; i++) {
      if (delivery.data[i].subTeam) return true;
    }
    return false;
  };

  render() {
    const classes = this.useStyles2();
    const {
      delivery,
      history,
      cancelDeliveryRequest,
      openDeliveryLockerRequest
    } = this.props;
    const {
      emptyRows,
      parameters,
      currentDelivery,
      idDeliveryUpdateInfoSubType
    } = this.state;
    const { width } = this.props;
    const isSmallScreen = false; //width === "xs" || width === "sm"; // is small screen

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead className="tableheader">
                <TableRow align="left">
                  <TableCell align="left">
                    <p id="table_Cell">{this.t("table.id")}</p>
                  </TableCell>

                  {!isSmallScreen ? (
                    <>
                      {this.checkIfExistsSubTeam() && (
                        <TableCell align="left">
                          <p id="table_Cell">{this.t("table.subTeam")}</p>
                        </TableCell>
                      )}

                      {(Boolean(parameters.useCondominium) ||
                        checkHandoverEmail()) && (
                        <>
                          <TableCell align="left">
                            <p id="table_Cell">{this.t("table.unit")}</p>
                          </TableCell>
                        </>
                      )}

                      <TableCell align="left">
                        <p id="table_Cell">{this.t("table.lockerAddress")}</p>
                      </TableCell>

                      <TableCell align="left">
                        <p id="table_Cell">
                          {this.t("table.compartmentNumber")}
                        </p>
                      </TableCell>

                      {checkHandoverEmail() && (
                        <TableCell align="left">
                          <p id="table_Cell">{this.t("table.client")}</p>
                        </TableCell>
                      )}

                      <TableCell align="left">
                        <p id="table_Cell">{this.t("table.createdAt")}</p>
                      </TableCell>

                      <TableCell align="left">
                        <p id="table_Cell">{this.t("table.remaining_time")}</p>
                      </TableCell>
                    </>
                  ) : null}

                  <TableCell align="left">
                    <p id="table_Cell">{this.t("table.status")}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p id="table_Cell">{this.t("table.actions")}</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {delivery.data &&
                  delivery.data.map(row => (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        <p id="table_Cell_Col">
                          {showCustomerReferenceIdOnTables(
                            row.customerReferenceId
                          )}
                        </p>
                      </TableCell>

                      {!isSmallScreen ? (
                        <>
                          {(row.subTeam || this.checkIfExistsSubTeam()) && (
                            <TableCell align="left">
                              <p id="table_Cell_Col">
                                {row.subTeam ? row.subTeam.name : "-"}
                              </p>
                            </TableCell>
                          )}

                          {(Boolean(parameters.useCondominium) ||
                            checkHandoverEmail()) && (
                            <>
                              <TableCell align="left">
                                <p id="table_Cell_Col">
                                  {row.subTeamUnits
                                    ? row.subTeamUnits.name
                                    : ""}
                                  <br />
                                  {row.subTeamSubUnits
                                    ? row.subTeamSubUnits.name
                                    : ""}
                                  <br />
                                  {row.subTeamSubUnitsContact
                                    ? row.subTeamSubUnitsContact.name.split(
                                        " "
                                      )[0]
                                    : ""}
                                </p>
                              </TableCell>
                            </>
                          )}

                          <TableCell align="left">
                            <p id="table_Cell_Col">{row.locker.address}</p>
                          </TableCell>

                          <TableCell align="left">
                            <p id="table_Cell_Col">
                              {row.compartment.compartmentNumber}
                            </p>
                          </TableCell>

                          {checkHandoverEmail() && (
                            <TableCell align="left">
                              <p id="table_Cell_Col">{row.team.businessName}</p>
                            </TableCell>
                          )}

                          <TableCell align="left">
                            <p id="table_Cell_Col">
                              {formatDate(row.created_at)}
                            </p>
                          </TableCell>

                          <TableCell align="left">
                            {parseInt(row.status) !== 2 ||
                            !parseInt(parameters.deliveryTimeoutOnCollect)
                              ? "-"
                              : diffTime(
                                  row.created_at,
                                  parseInt(
                                    parameters.deliveryTimeoutOnCollect
                                  ) + 1,
                                  this.t
                                )}
                          </TableCell>
                        </>
                      ) : null}

                      <TableCell align="left">
                        <p id="table_Cell_Col">
                          {Boolean(parameters.useLaundry)
                            ? constants.deliveryStatusLaundryTable[row.status]
                            : constants.deliveryStatusTable()[row.status]}
                        </p>
                      </TableCell>
                      <TableCell align="left" id="table-sm__svg">
                        <p id="table_Cell_Col">
                          <SimpleActionButton
                            dataFatherIsActive={
                              parseInt(row.status) !== 3 &&
                              parseInt(row.status) !== 6 &&
                              parseInt(row.status) !== 7
                            }
                            id={Math.random()}
                            actions={[
                              () => history.push(`/delivery/view/${row.id}`),
                              () => history.push(`/delivery/edit/${row.id}`),
                              () => {
                                this.setState({
                                  currentDelivery: row,
                                  openDialogCancel: true
                                });
                              }
                            ]}
                            options={[
                              constants.tableActionOptions().show,
                              constants.tableActionOptions().edit,
                              constants.tableActionOptions().cancel
                            ].map(item => item.label)}
                          />
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={delivery.total}
            rowsPerPage={delivery.perPage}
            page={delivery.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage={this.t("table.lines_per_page")}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} ${this.t("table.of")} ${count}`
            }
            rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
            component="div"
          />
        </div>
        <CustomDialogCancelDelivery
          useCustomOpen={true}
          customOpen={this.state.openDialogCancel}
          handleCustomOpenCancel={() => {
            this.setState({ openDialogCancel: false });
          }}
          noButton={true}
          className={classes.button}
          title={this.t("table.dialogTitle")}
          buttonLabel={this.t("table.dialogButton")}
          message={this.t("table.dialogMessage")}
          subMessage={this.t("table.dialogSubMessage")}
          finalMessage={this.t("table.dialogFinalMessage")}
          onConfirm={() => {
            cancelDeliveryRequest(
              currentDelivery.id,
              idDeliveryUpdateInfoSubType
            );
          }}
          disabled={delivery.loading}
          options={this.props.deliveryUpdateInfoSubType.data}
          onChangePageDeliveryUpdateInfoSubType={value => {
            this.setState({
              idDeliveryUpdateInfoSubType: value
            });
          }}
          onOpenPort={() => {
            openDeliveryLockerRequest({
              idDelivery: currentDelivery.id
            });
          }}
        />
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  delivery: state.delivery,
  deliveryUpdateInfoSubType: state.deliveryUpdateInfoSubType
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withTranslation("delivery")(DeliveryPaginationTable)));

import React from "react";

// Material
import { Typography, withStyles, Paper, Grid } from "@material-ui/core";

import { CountDeliveries } from "../../pages/home/styled";

import { materialStyle, colors } from "../../styles";
import { Label } from "./styles";
import history from "../../routes/history";

function CardInfo({
  classes,
  label,
  subLabel,
  status,
  numberInfo,
  labelColor,
  icon,
  params
}) {
  return (
    <Paper
      className={classes.paperHomeCentered}
      onClick={
        status
          ? () =>
              history.push(`delivery?status=${status}&${params ? params : ""}`)
          : () => {}
      }
    >
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid container spacing={2} direction="row">
          {icon && (
            <Grid item>
              <div
                style={{
                  borderRadius: 50,
                  height: 40,
                  width: 40,
                  backgroundColor: colors.dark2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img src={icon} alt="icon" style={{ width: 20, height: 20 }} />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm container justify="center" alignItems="center">
            <Grid
              item
              xs
              container
              direction="column"
              spacing={1}
              alignItems="center"
              justify="center"
            >
              <Grid item xs>
                <Label>{label}</Label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item sm>
            <CountDeliveries
              color={labelColor}
              className="home-content-sm__count-deliveries"
            >
              {numberInfo || 0}
            </CountDeliveries>
          </Grid>

          <Grid item sm>
            {subLabel && (
              <Typography
                style={{ color: "rgba(27, 48, 27, .7)", fontSize: 13 }}
                gutterBottom
                variant="p"
              >
                {subLabel}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(materialStyle)(CardInfo);

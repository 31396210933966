import queryString from "query-string";
import React, { Component } from "react";

// Material
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global
import Container from "../../components/Container";
import LockersPaginationTable from "../../components/LockersPaginationTable";

import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as TeamsActions } from "../../store/ducks/teams";

// Features roles
import featureRoles from "./roles";

// Help Functions
import { checkUserRoles } from "../../services/permissions";

import { constants } from "../../config";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import ButtonClick from "../../components/ButtonClick";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import FilterTitle from "../../components/FilterTitle";
import { withTranslation } from "react-i18next";

class Lockers extends Component {
  state = {
    idLocker: "",
    isActive: constants.lockerStatusOptions[0].value,
    idTeam: ""
  };
  t = this.props.t;

  componentWillMount() {
    const { lockersRequest, lockersSelectRequest, teamsRequest } = this.props;

    const query = this.handleQueryString({
      perPage: 1000
      // isActive: 1
    });

    lockersRequest(query);
    lockersSelectRequest(query);

    teamsRequest("");
  }

  handleQueryString(query) {
    return queryString.stringify(query);
  }

  handleChange = key => event => {
    const { lockersSelectRequest } = this.props;

    if (key === "idTeam") {
      lockersSelectRequest(
        this.handleQueryString({
          perPage: 1000,
          idTeam: event.target.value
        })
      );
    }

    this.setState({ [key]: event.target.value });
  };

  handleClear = () => {
    const { lockersRequest, lockersSelectRequest } = this.props;

    const query = this.handleQueryString({
      perPage: 1000
    });

    lockersRequest(query);
    lockersSelectRequest(query);

    this.setState({
      idLocker: "",
      lockerNumber: "",
      idTeam: ""
      // isActive: 1
    });
  };

  handleSearch = () => {
    const { idLocker, lockerNumber, idTeam } = this.state;
    const { lockersRequest, lockers } = this.props;

    if (!idLocker && !idTeam) return;

    lockersRequest(
      queryString.stringify({
        idLocker,
        lockerNumber,
        idTeam,
        perPage: lockers.perPage,
        page: 1
      })
    );
  };

  renderCreate = () => {
    const check = checkUserRoles(featureRoles.createLockerRole);

    return (
      check && (
        <>
          <h3>Criar Locker</h3>
          <ButtonClick
            onClick={() => this.props.history.push("/lockers/create")}
          >
            Criar
          </ButtonClick>
          <br />
        </>
      )
    );
  };

  render() {
    const { history, lockers, teams } = this.props;
    const { idLocker, idTeam } = this.state;

    return (
      <Container>
        <ThemeTextFildGreen>
          <FilterTitle />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomAutoComplete
                options={teams.data}
                value={teams.data.find(item => item.id === idTeam) || null}
                onChange={newValue => {
                  this.handleChange("idTeam")({
                    target: { value: newValue ? newValue.id : "" }
                  });
                }}
                disabled={teams.loading}
                loading={teams.loading}
                getOptionLabelKey="name"
                label={this.t("filters.client")}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomAutoComplete
                options={lockers.dataSelect || []}
                value={
                  lockers.dataSelect.find(item => item.id === idLocker) || null
                }
                onChange={newValue => {
                  this.handleChange("idLocker")({
                    target: { value: newValue ? newValue.id : "" }
                  });
                }}
                disabled={lockers.loading}
                loading={lockers.loading}
                getOptionLabelKey="address"
                label={this.t("filters.locker")}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <ButtonClick onClick={() => this.handleSearch()}>
                {this.t("filters.search")}
              </ButtonClick>
              <ButtonClick onClick={() => this.handleClear()}>
                {this.t("filters.clear")}
              </ButtonClick>
            </Grid>
          </Grid>
          <LockersPaginationTable history={history} />
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  teams: state.teams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...LockerActions, ...TeamsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("lockers")(withStyles(materialStyle)(Lockers)));

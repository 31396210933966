import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { materialStyle } from "../../styles";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

import { Actions } from "../../store/ducks/lockerRentalItemOptions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      overflowX: "visible"
    }
  },
  table: {
    minWidth: 650
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const useStylesActive = makeStyles({
  redCheckbox: {
    color: "#f44336", // Cor padrão
    "&.Mui-checked": {
      color: "#f44336" // Cor quando o checkbox estiver marcado
    },
    "&.Mui-disabled": {
      color: "#ffcdd2" // Cor mais clara quando desabilitado
    }
  }
});

function LockerRentalItemOptionTableAssociation({
  lockerRentalItemOptions,
  onChange,
  locker,
  edit
}) {
  const classes = useStyles();
  const classesActive = useStylesActive();
  const { t } = useTranslation("config");
  const dispatch = useDispatch();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} id="team-table-businessedit-sm">
        <TableHead className="tableheader">
          <TableRow>
            <TableCell
              className="tablecell-header tablecell-header__initial-table"
              align="left"
            >
              {t("staff.business")}
            </TableCell>
            <TableCell className="tablecell-header" align="center">
              {t("staff.rule")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locker.data.map(row => (
            <TableRow key={row.id}>
              <TableCell className="tablecell tablecell-header__initial-table">
                {row.address}
              </TableCell>
              <TableCell className="tablecell" style={{ maxWidth: 100 }}>
                <FormGroup row>
                  {row.compartments.map(comp => {
                    const check = lockerRentalItemOptions.currentRentalCompartmentsFullData.find(
                      activated => activated.idCompartment === comp.id
                    );

                    // verifica se porta está em uso
                    const deviceInUse = check ? check.inUse : false;

                    return (
                      <FormControlLabel
                        key={comp.id}
                        control={
                          <Checkbox
                            disabled={
                              !lockerRentalItemOptions.setEditable ||
                              deviceInUse
                            }
                            value={comp.id}
                            checked={
                              lockerRentalItemOptions.currentRentalCompartments
                                .length
                                ? lockerRentalItemOptions.currentRentalCompartments.find(
                                    actual => actual.id === comp.id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            onChange={e => {
                              let finalResult =
                                lockerRentalItemOptions.currentRentalCompartments;

                              if (e.target.checked) {
                                finalResult = [
                                  ...lockerRentalItemOptions.currentRentalCompartments,
                                  comp
                                ];
                              } else {
                                finalResult = lockerRentalItemOptions.currentRentalCompartments.filter(
                                  i => i.id !== comp.id
                                );
                              }

                              dispatch(
                                Actions.setCurrentLockerRentalItemOptionCompartments(
                                  finalResult
                                )
                              );
                            }}
                            name={String(comp.compartmentNumber)}
                            color="primary"
                            classes={{
                              root: deviceInUse && classesActive.redCheckbox
                            }}
                          />
                        }
                        label={`${comp.compartmentNumber}${
                          deviceInUse ? " (em uso)" : ""
                        }`}
                      />
                    );
                  })}
                </FormGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const mapStateToProps = state => ({
  error: state.business.error,
  locker: state.locker,
  lockerRentalItemOptions: state.lockerRentalItemOptions
});

export default connect(mapStateToProps)(
  withStyles(materialStyle)(LockerRentalItemOptionTableAssociation)
);

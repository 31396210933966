import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { colors } from "../../styles";

export const Title = styled.p`
  font-size: 15px;
  color: #4a4b4a;
  margin: 10px;
  font-weight: 900 !important;
  font-family: "Livvic", sans-serif !important;
  text-transform: lowercase;
  text-align: center;
  margin: 0;
`;

export const BoxGraph = styled(Box)`
  /*Small devices (landscape phones, 576px and up)*/
  @media (max-width: 641px) {
    display: flex;
    flex-direction: column;
    background-color: red;
  }
`;

export const ContainerLastDeliveries = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  color: #4a4b4a;

  &:hover {
    overflow: auto;
  }

  @media (max-width: 641px) {
    padding: 0;
  }
`;

export const ContainerBox = styled.div`
  @media (max-width: 641px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const CountDeliveries = styled.span`
  font-size: 40px;
  color: ${props => (props.color ? colors.primary : "#4a4b4a")};
  font-weight: bold;
  font-family: "Livvic", sans-serif !important;
`;

export const TooltipContent = styled.div`
  min-width: 100px;
  min-height: 70px;
  background-color: #fff;
`;

export const ContainerTolltip = styled.div`
  height: 80px;
  width: 300px;
  background-color: #cccccc;
  border-radius: 8px;
  padding: 10px;
`;

export const ContainerTitleMoreItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const ContainerEmptyLastDeliveries = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 641px) {
    justify-content: left;
  }

  small {
    @media (max-width: 641px) {
      font-size: 14px;
    }
  }
`;

export const Types = {
  getLockerRentalItemOptionsRequest: "getLockerRentalItemOptions/Request",
  getLockerRentalItemOptionsSuccess: "getLockerRentalItemOptions/Success",
  getLockerRentalItemOptionsError: "getLockerRentalItemOptions/Error",

  showLockerRentalItemOptionsRequest: "showLockerRentalItemOptions/Request",
  showLockerRentalItemOptionsSuccess: "showLockerRentalItemOptions/Success",
  showLockerRentalItemOptionsError: "showLockerRentalItemOptions/Error",

  updateLockerRentalItemOptionsRequest: "updateLockerRentalItemOptions/Request",
  updateLockerRentalItemOptionsSuccess: "updateLockerRentalItemOptions/Success",
  updateLockerRentalItemOptionsError: "updateLockerRentalItemOptions/Error",

  createLockerRentalItemOptionsRequest: "createLockerRentalItemOptions/Request",
  createLockerRentalItemOptionsSuccess: "createLockerRentalItemOptions/Success",
  createLockerRentalItemOptionsError: "createLockerRentalItemOptions/Error",

  resetLockerRentalItemOptionsState: "resetLockerRentalItemOptionsState",
  setSnackbarVisibleLockerRentalItemOptions:
    "setSnackbarVisible/LockerRentalItemOptions",
  setSnackbarLockerRentalItemOptions: "setSnackbar/LockerRentalItemOptions",

  setCurrentLockerRentalItemOptionCompartments:
    "setCurrentLockerRentalItemOptionCompartments",
  setCurrentLockerRentalItemOptionCompartmentsFullData:
    "setCurrentLockerRentalItemOptionCompartmentsFullData",

  setEditableLockerRentalItemOption: "setEditableLockerRentalItemOption"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  currentRental: null,
  snackbarVisible: false,
  snackbarType: "success",
  snackbarMessage: "",
  total: null,
  perPage: null,
  lastPage: null,
  page: null,
  setEditable: false,
  currentRentalCompartments: [],
  currentRentalCompartmentsFullData: []
};

export default function lockerRentalItemOptions(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.resetLockerRentalItemOptionsState:
      return INITAL_STATE;

    case Types.setSnackbarVisibleLockerRentalItemOptions:
      return {
        ...state,
        snackbarVisible: action.payload.status,
        error: null
      };

    case Types.setCurrentLockerRentalItemOptionCompartments:
      return {
        ...state,
        currentRentalCompartments: action.payload.data
      };
    case Types.setCurrentLockerRentalItemOptionCompartmentsFullData:
      return {
        ...state,
        currentRentalCompartmentsFullData: action.payload.data
      };

    case Types.setSnackbarLockerRentalItemOptions:
      return {
        ...state,
        ...action.payload.data
      };

    case Types.setEditableLockerRentalItemOption:
      return {
        ...state,
        setEditable: action.payload.status
      };

    case Types.getLockerRentalItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getLockerRentalItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getLockerRentalItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showLockerRentalItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentRental: null
      };

    case Types.showLockerRentalItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data
      };

    case Types.showLockerRentalItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentRental: null
      };

    case Types.createRentalRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.createRentalSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createRentalError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true
      };

    case Types.searchRentalRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.searchRentalSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
        data: action.payload.data.data
      };

    case Types.searchRentalError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateLockerRentalItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.updateLockerRentalItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data,
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: "Atualizado!",
        setEditable: false
      };

    case Types.updateLockerRentalItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: "Não foi possível atualizar"
      };

    case Types.createLockerRentalItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.createLockerRentalItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        currentRental: action.payload.data,
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: "Criado com sucesso!",
        setEditable: false
      };

    case Types.createLockerRentalItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: "Não foi possível criar"
      };

    case Types.openRentalLockerRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
        snackbarVisible: INITAL_STATE.snackbarVisible
      };

    case Types.openRentalLockerSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.openRentalLockerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.sendRentalEmailCodominiumRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.sendRentalEmailCodominiumSuccess:
      return {
        ...state,
        loading: false,
        snackbarMessage: "E-mail enviado!",
        snackbarType: "success",
        snackbarVisible: true
      };

    case Types.sendRentalEmailCodominiumError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  setSnackbarVisibleLockerRentalItemOptions: status => ({
    type: Types.setSnackbarVisibleLockerRentalItemOptions,
    payload: { status }
  }),
  setSnackbarLockerRentalItemOptions: data => ({
    type: Types.setSnackbarLockerRentalItemOptions,
    payload: { data }
  }),

  getLockerRentalItemOptionsRequest: data => ({
    type: Types.getLockerRentalItemOptionsRequest,
    payload: { data }
  }),

  getLockerRentalItemOptionsSuccess: data => ({
    type: Types.getLockerRentalItemOptionsSuccess,
    payload: { data }
  }),

  getLockerRentalItemOptionsError: error => ({
    type: Types.getLockerRentalItemOptionsError,
    payload: { error }
  }),

  showLockerRentalItemOptionsRequest: id => ({
    type: Types.showLockerRentalItemOptionsRequest,
    payload: { id }
  }),

  showLockerRentalItemOptionsSuccess: data => ({
    type: Types.showLockerRentalItemOptionsSuccess,
    payload: { data }
  }),

  showLockerRentalItemOptionsError: error => ({
    type: Types.showLockerRentalItemOptionsError,
    payload: { error }
  }),

  createRentalRequest: data => ({
    type: Types.createRentalRequest,
    payload: { data }
  }),

  createRentalSuccess: data => ({
    type: Types.createRentalSuccess,
    payload: { data }
  }),

  createRentalError: error => ({
    type: Types.createRentalError,
    payload: { error }
  }),

  searchRentalRequest: data => ({
    type: Types.searchRentalRequest,
    payload: { data }
  }),

  searchRentalSuccess: data => ({
    type: Types.searchRentalSuccess,
    payload: { data }
  }),

  searchRentalError: error => ({
    type: Types.searchRentalError,
    payload: { error }
  }),

  updateLockerRentalItemOptionsRequest: (id, dataUpdate) => ({
    type: Types.updateLockerRentalItemOptionsRequest,
    payload: { id, dataUpdate }
  }),

  updateLockerRentalItemOptionsSuccess: data => ({
    type: Types.updateLockerRentalItemOptionsSuccess,
    payload: { data }
  }),

  updateLockerRentalItemOptionsError: data => ({
    type: Types.updateLockerRentalItemOptionsError,
    payload: { data }
  }),

  createLockerRentalItemOptionsRequest: data => ({
    type: Types.createLockerRentalItemOptionsRequest,
    payload: { data }
  }),

  createLockerRentalItemOptionsSuccess: data => ({
    type: Types.createLockerRentalItemOptionsSuccess,
    payload: { data }
  }),

  createLockerRentalItemOptionsError: data => ({
    type: Types.createLockerRentalItemOptionsError,
    payload: { data }
  }),

  openRentalLockerRequest: data => ({
    type: Types.openRentalLockerRequest,
    payload: { data }
  }),

  openRentalLockerSuccess: data => ({
    type: Types.openRentalLockerSuccess,
    payload: { data }
  }),

  openRentalLockerError: error => ({
    type: Types.openRentalLockerError,
    payload: { error }
  }),

  setEditableLockerRentalItemOption: status => ({
    type: Types.setEditableLockerRentalItemOption,
    payload: { status }
  }),

  sendRentalEmailCodominiumRequest: idRental => ({
    type: Types.sendRentalEmailCodominiumRequest,
    payload: { idRental }
  }),

  sendRentalEmailCodominiumSuccess: () => ({
    type: Types.sendRentalEmailCodominiumSuccess
  }),

  sendRentalEmailCodominiumError: error => ({
    type: Types.sendRentalEmailCodominiumError,
    payload: { error }
  }),

  resetLockerRentalItemOptionsState: () => ({
    type: Types.resetLockerRentalItemOptionsState
  }),

  setSnackbar: data => ({
    type: Types.setSnackbar,
    payload: { data }
  }),

  setCurrentLockerRentalItemOptionCompartments: data => ({
    type: Types.setCurrentLockerRentalItemOptionCompartments,
    payload: { data }
  }),

  setCurrentLockerRentalItemOptionCompartmentsFullData: data => ({
    type: Types.setCurrentLockerRentalItemOptionCompartmentsFullData,
    payload: { data }
  })
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  KeyboardArrowRight as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableHead,
  withWidth,
  TableContainer
} from "@material-ui/core";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";
import { Actions as CustomerLogAbbreviationActions } from "../../store/ducks/customerLogAbbreviation";

// Components
import SimpleActionButton from "../SimpleActionButton";
import AlertDialog from "../AlertDialog";
import ModalChangeStatus from "./components/ModalChangeStatus";

import history from "../../routes/history";
// import checkHandoverEmail from "../../services/checkHandoverEmail";

import { constants } from "../../config";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class CompartmentsTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5,
    modalVisible: false,
    currentCompartment: null,
    showDialog: false,
    idCompartment: null
  };

  t = this.props.t;

  static defaultProps = {
    actions: [() => {}],

    options: ["DEFAULT"]
  };

  handleChangePage = (event, newPage) => {
    const { getCompartmentsRequest, currentLocker } = this.props;
    const { rowsPerPage } = this.state;

    getCompartmentsRequest(
      `idLocker=${currentLocker.id}&page=${newPage + 1}&perPage=${rowsPerPage}`
    );
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value);

    this.setState({ rowsPerPage });

    const { getCompartmentsRequest, currentLocker } = this.props;

    getCompartmentsRequest(
      `perPage=${rowsPerPage}&idLocker=${currentLocker.id}`
    );
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500,
        fontSize: 50
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  handleOpenAlertDialog = id => {
    this.setState({
      showDialog: !this.state.showDialog,
      idCompartment: id
    });
  };

  handleOpenCompartment = () => {
    const { idCompartment } = this.state;
    const { openCompartmentRequest } = this.props;
    openCompartmentRequest(idCompartment);
  };

  renderButtonItems = id => {
    let actions = [];
    let tmp = [
      this.t("doors_table.open_action"),
      this.t("doors_table.edit_action")
    ];
    let options = [];

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    for (let i = 0; i < tmp.length; i++) {
      switch (i) {
        case 0:
          options[i] = tmp[i];
          actions[i] = () => this.handleOpenAlertDialog(id);
          break;
        case 1:
          options[i] = tmp[i];
          actions[i] = () => history.push(`/compartments/edit/${id}`);
          break;
        case 2:
          if (Boolean(parameters.useCondominium)) {
            options[i] = tmp[i];
            actions[i] = () => {
              this.props.setModalChangeStatusVisible(true);
              this.props.setCompartment({
                id
              });
              this.props.customerLogAbbreviationRequest();
            };
          }

          break;
        default:
          options[i] = tmp[i];
          actions[i] = () => {};
      }
    }

    return {
      actions,
      options
    };
  };

  render() {
    const classes = this.useStyles2();
    const { compartments, width } = this.props;
    const { emptyRows } = this.state;
    const isSmallScreen = width === "xs" || width === "sm"; // is small screen
    const align = !isSmallScreen ? "left" : "center";

    return (
      <Paper className={classes.root}>
        <ModalChangeStatus />
        <div className={classes.tableWrapper}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align={align}>
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      {this.t("doors_table.door")}
                    </p>
                  </TableCell>
                  <TableCell align={align}>
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      {this.t("doors_table.status")}
                    </p>
                  </TableCell>
                  <TableCell align={align}>
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      {this.t("doors_table.dimensions")}
                    </p>
                  </TableCell>
                  <TableCell align={align}>
                    <p
                      style={{ color: "#006660", fontSize: "18px", margin: 0 }}
                    >
                      {this.t("doors_table.actions")}
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {compartments.data &&
                  compartments.data.map(row => (
                    <TableRow key={row.id}>
                      <TableCell align={align}>
                        {row.compartmentNumber}
                      </TableCell>
                      <TableCell align={align}>
                        {row.compartmentAvailability.description}
                      </TableCell>
                      <TableCell align={align}>
                        {row.compartmentIdentifier
                          ? row.compartmentIdentifier.description
                          : ""}
                      </TableCell>
                      <TableCell align={align}>
                        <SimpleActionButton
                          label={this.renderButtonItems(row.id).options[0]}
                          id={Math.random()}
                          actions={this.renderButtonItems(row.id).actions}
                          options={this.renderButtonItems(row.id).options}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter className="table-footer">
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={constants.optionPerPage}
                    colSpan={3}
                    count={compartments.total}
                    rowsPerPage={compartments.perPage}
                    page={compartments.page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true
                    }}
                    labelRowsPerPage="Linhas por página"
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to === -1 ? count : to} de ${count}`
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <AlertDialog
          open={this.state.showDialog}
          className={classes.button}
          title={this.t("doors_table.attention")}
          buttonLabel={this.t("doors_table.open_action")}
          cancelButtonText={this.t("doors_table.cancel")}
          confirmButtonText={this.t("doors_table.confirm")}
          message={this.t("doors_table.confirm_message")}
          onConfirm={this.handleOpenCompartment}
          disabled={false}
          handleClose={this.handleOpenAlertDialog}
        />
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  compartments: state.compartment,
  currentLocker: state.locker.currentLocker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...CustomerLogAbbreviationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withTranslation("lockers")(CompartmentsTable)));

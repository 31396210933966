import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import login from "./login";
import teams from "./teams";
import compartment from "./compartment";
import locker from "./locker";
import users from "./users";
import business from "./business";
import orders from "./orders";
import delivery from "./delivery";
import reports from "./reports";
import members from "./members";
import roles from "./roles";
import location from "./location";
import subTeams from "./subTeam";
import configs from "./configs";
import subTeamUnits from "./subTeamUnits";
import subTeamSubUnits from "./subTeamSubUnits";
import subTeamSubUnitsContact from "./subTeamSubUnitsContact";
import surveyForm from "./surveyForm";
import lockerStructureConditionOptions from "./lockerStructureConditionOptions";
import paintConditionOptions from "./paintConditionOptions";
import customerFeedback from "./customerFeedback";
import partnerNeposTickets from "./partners/nepos/tickets";
import notification from "./notification";
import customerLogAbbreviation from "./customerLogAbbreviation";
import customerLogs from "./customerLogs";
import deliveryEmails from "./deliveryEmails";
import groups from "./groups";
import things from "./things";
import thingTypes from "./thingTypes";
import externalProfile from "./externalProfile";
import azureActiveDirectory from "./azureActiveDirectory";
import deliveryUpdateInfoSubType from "./deliveryUpdateInfoSubType";
import userCreditTicket from "./userCreditTicket";
import rental from "./rental";
import lockerRentalItemOptions from "./lockerRentalItemOptions";

import history from "../../routes/history";

import { Types as LoginTypes } from "../../store/ducks/login";
import laundry from "./laundry";
import integrations from "./integrations";
import rentalItem from "./rentalItems";

const appReducer = combineReducers({
  router: connectRouter(history),
  login,
  teams,
  compartment,
  locker,
  users,
  business,
  orders,
  delivery,
  reports,
  members,
  roles,
  location,
  subTeams,
  configs,
  subTeamUnits,
  subTeamSubUnits,
  subTeamSubUnitsContact,
  surveyForm,
  lockerStructureConditionOptions,
  paintConditionOptions,
  customerFeedback,
  partnerNeposTickets,
  notification,
  customerLogAbbreviation,
  customerLogs,
  deliveryEmails,
  groups,
  things,
  thingTypes,
  externalProfile,
  azureActiveDirectory,
  deliveryUpdateInfoSubType,
  userCreditTicket,
  rental,
  laundry,
  integrations,
  rentalItem,
  lockerRentalItemOptions
});

const rootReducer = (state, action) => {
  if (action.type === LoginTypes.logout) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React, { Component } from "react";
import queryString from "query-string";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Global
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import { materialStyle } from "../../styles";

// Material
import { Grid, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryAcions } from "../../store/ducks/delivery";
import { Actions as LockerAcions } from "../../store/ducks/locker";
import { Actions as CompartmentAcions } from "../../store/ducks/compartment";
import { Actions as BusinessAcions } from "../../store/ducks/business";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { Actions as DeliveryUpdateInfoSubTypeActions } from "../../store/ducks/deliveryUpdateInfoSubType";
import { Actions as TeamsActions } from "../../store/ducks/teams";
import { Actions as RentalItemActions } from "../../store/ducks/rentalItems";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import constants from "../../config/constants";
import storageKeys from "../../services/keys";
import { getUTCDateDatabase } from "../../functions/formatDate";

import checkHandOverEmail from "../../services/checkHandoverEmail";
import Snackbar from "../../components/Snackbar";
import ButtonClick from "../../components/ButtonClick";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import FilterTitle from "../../components/FilterTitle";
import { withTranslation } from "react-i18next";
import RentalItemDeliveryItemsTable from "../../components/RentalItemDeliveryItemsTable";

class RentalItemOrders extends Component {
  state = {
    customerReferenceId: "",
    initialDate: null,
    finalDate: null,
    idLocker: "all",
    idCompartment: "all",
    status: constants.deliveryStatus.all.id,
    email: "",
    search: "",
    idSubTeam: "all",
    idSubTeamUnits: "all",
    idSubTeamSubUnits: "all",
    idSubTeamSubUnitsContact: "all",
    dashboardParams: null,
    perPage: 10,
    idTeam: ""
  };

  t = this.props.t;

  handleInit() {
    console.log("handleInit");
    const {
      getRentalItemDeliveryItemsRequest,

      resetDeliveryState,
      history: { location },
      // deliveryUpdateInfoSubTypeRequest,
      teamsRequest
    } = this.props;
    resetDeliveryState();
    teamsRequest("perPage=1000");
    // deliveryUpdateInfoSubTypeRequest();
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({
      parameters
    });

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      this.setState({
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    let search = queryString.parse(location.search);

    if (search.status) {
      const keys = Object.keys(constants.deliveryStatus);

      const statusSearch = keys.find(
        item => constants.deliveryStatus[item].slug === search.status
      );

      if (statusSearch) {
        this.setState({ status: constants.deliveryStatus[statusSearch].id });
        search.status = constants.deliveryStatus[statusSearch].id;
      }
    }

    if (search.idLocker) {
      this.setState({ idLocker: search.idLocker });
    }

    const initialDate = search.initialDate || null;
    const finalDate = search.finalDate || null;

    this.setState({
      ...this.state,
      ...queryString.parse(location.search),
      initialDate,
      finalDate,
      search: queryString.stringify(search)
    });
    getRentalItemDeliveryItemsRequest(queryString.stringify(search));
  }

  componentWillMount() {
    console.log("componentWillMount");
    this.handleInit();
  }

  handleChange = key => event => {
    console.log("handleChange");
    const { getCompartmentsRequest } = this.props;
    const value = event.target.value;
    let search = JSON.parse(localStorage.getItem(storageKeys.search));

    if (key === "idLocker") {
      localStorage.setItem(
        storageKeys.search,
        JSON.stringify({
          ...search,
          idLocker: `idLocker=${value}`
        })
      );
      search = JSON.parse(localStorage.getItem(storageKeys.search));
      getCompartmentsRequest(`idLocker=${value}&perPage=100`);
    }

    this.setState({ [key]: value });
  };

  checkIfSearch = () => {
    const {
      customerReferenceId,
      status,
      idLocker,
      idCompartment,
      createdAt,
      email,
      idSubTeam,
      initialDate,
      finalDate
    } = this.state;

    if (
      !customerReferenceId &&
      !status &&
      !idLocker &&
      !idCompartment &&
      !createdAt &&
      !email &&
      !idSubTeam &&
      !initialDate &&
      !finalDate
    )
      return false;

    return true;
  };

  convertValuesOfObjectKeysToQueryString = object => {
    const keys = Object.keys(object);
    let search = "";

    for (let i = 0; i < keys.length; i++) {
      search += `${object[keys[i]]}&`;
    }

    return search;
  };

  handleSearch = () => {
    console.log("handleSearch");
    const {
      idLocker,
      idSubTeam,
      customerReferenceId,
      idCompartment,
      status,
      email,
      initialDate,
      finalDate,
      perPage,
      idTeam
    } = this.state;

    const { searchDeliveryRequest } = this.props;

    if (!this.checkIfSearch()) return;

    const _search = {
      email,
      idLocker,
      idSubTeam,
      customerReferenceId,
      idCompartment,
      status,
      initialDate: initialDate ? getUTCDateDatabase(initialDate) : "",
      finalDate: finalDate ? getUTCDateDatabase(finalDate) : "",
      perPage,
      idTeam
    };

    const stringSearch = queryString.stringify(_search);

    this.setState(
      {
        search: stringSearch
      },
      () => searchDeliveryRequest(stringSearch)
    );
    this.props.history.push(`/delivery?${stringSearch}`);
  };

  handleClear = () => {
    const { resetLockers, resetCompartments, resetSubTeams } = this.props;

    localStorage.removeItem(storageKeys.search);

    this.props.history.push("delivery");

    this.setState({
      customerReferenceId: "",
      initialDate: null,
      finalDate: null,
      idLocker: "all",
      idCompartment: "all",
      status: constants.deliveryStatus.all.id,
      email: "",
      search: "",
      idSubTeam: "all",
      idSubTeamUnits: "all",
      idSubTeamSubUnits: "all",
      idSubTeamSubUnitsContact: "all",
      dashboardParams: null,
      perPage: 10,
      idTeam: ""
    });

    resetSubTeams();
    resetLockers();
    resetCompartments();

    // this.handleInit();
    this.handleSearch();
  };

  render() {
    const {
      history,
      classes,
      lockers,
      compartments,
      delivery,
      setSnackbarVisibleDelivery,
      lockersRequest,
      getSubTeamsRequest
    } = this.props;

    const {
      customerReferenceId,
      status,
      idLocker,
      idCompartment,
      initialDate,
      finalDate,

      idTeam
    } = this.state;

    const { teams, getCompartmentsRequest } = this.props;

    const labelStatus = Boolean(this.state.parameters.useLaundry)
      ? constants.deliveryStatusLaundry
      : constants.deliveryStatus;

    return (
      <Container title="gerenciar entregas" id="title-sm__h3">
        {delivery.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <div className="helper-spacing-top-mobile" />
            <Snackbar
              message={delivery.snackbarMessage}
              open={delivery.snackbarVisible}
              variant={delivery.snackbarType}
              onClose={() => setSnackbarVisibleDelivery(false)}
            />
            <FilterTitle />
            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-field"
                  label={this.t("delivery_number")}
                  className={classes.textField1}
                  value={customerReferenceId}
                  onChange={this.handleChange("customerReferenceId")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <CustomAutoComplete
                  options={teams.data}
                  value={teams.data.find(item => item.id === idTeam)}
                  onChange={newValue => {
                    this.handleChange("idTeam")({
                      target: { value: newValue ? newValue.id : "" }
                    });

                    const query = `perPage=1000&idTeam=${newValue.id}`;
                    lockersRequest(query);
                    getSubTeamsRequest(query);
                  }}
                  disabled={teams.loading || !teams.data.length}
                  loading={teams.loading}
                  getOptionLabelKey="name"
                  label={this.t("client")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomAutoComplete
                  options={lockers.data}
                  value={lockers.data.find(item => item.id === idLocker)}
                  onChange={newValue => {
                    this.setState({
                      idLocker: newValue.id
                    });
                    getCompartmentsRequest(
                      `idLocker=${newValue.id}&perPage=100`
                    );
                  }}
                  disabled={lockers.loading || !lockers.data.length}
                  loading={lockers.loading}
                  getOptionLabelKey="address"
                  label={this.t("locker")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomAutoComplete
                  options={compartments.data}
                  value={compartments.data.find(
                    item => item.id === idCompartment
                  )}
                  onChange={newValue => {
                    this.setState({
                      idCompartment: newValue.id
                    });
                  }}
                  disabled={!compartments.data.length || compartments.loading}
                  loading={compartments.loading}
                  getOptionLabelKey="compartmentNumber"
                  label={this.t("port")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label={this.t("status")}
                  className={classes.textField1}
                  value={status}
                  onChange={this.handleChange("status")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {Object.keys(labelStatus).map(
                    status =>
                      labelStatus[status].visible && (
                        <MenuItem
                          key={labelStatus[status].slug}
                          value={labelStatus[status].id}
                        >
                          {labelStatus[status].label}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    className={classes.textField1}
                    disableToolbar
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    label={this.t("start_date")}
                    value={initialDate}
                    onChange={data => this.setState({ initialDate: data })}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputProps={{
                      margin: "normal"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={this.t("start_date_placeholder")}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    className={classes.textField1}
                    disableToolbar
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    label={this.t("end_date")}
                    value={finalDate}
                    onChange={data => this.setState({ finalDate: data })}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputProps={{
                      margin: "normal"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={this.t("end_date_placeholder")}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid container spacing={1}>
              <ButtonClick
                onClick={() => this.handleSearch()}
                disabled={!this.checkIfSearch()}
              >
                {this.t("search")}
              </ButtonClick>
              <ButtonClick
                onClick={() => this.handleClear()}
                disabled={!this.checkIfSearch()}
              >
                {this.t("clear")}
              </ButtonClick>
              {(!Boolean(this.state.parameters.useCondominium) ||
                checkHandOverEmail()) && (
                <ButtonClick onClick={() => history.push("/delivery/create")}>
                  Criar uma entrega
                </ButtonClick>
              )}
            </Grid>

            <RentalItemDeliveryItemsTable
              history={history}
              perPage={() => this.state.perPage}
              search={() => this.state.search}
              onChangePerPage={value => {
                this.setState({ perPage: value });
              }}
            />
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  compartments: state.compartment,
  ...state
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryAcions,
      ...LockerAcions,
      ...CompartmentAcions,
      ...BusinessAcions,
      ...SubTeamActions,
      ...DeliveryUpdateInfoSubTypeActions,
      ...TeamsActions,
      ...RentalItemActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("delivery")(withStyles(materialStyle)(RentalItemOrders)));

import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { constants } from "../../config";
import { materialStyle } from "../../styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(materialStyle);

export default function SplitButton({
  actions,
  options,
  label,
  dataFatherIsActive
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();

  const handleClick = () => {
    if (!actions[0]) {
      handleToggle();
    } else {
      actions[0]();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
    actions[index]();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        className={classes.buttonGroup}
        variant="contained"
        ref={anchorRef}
        aria-label="Split button"
      >
        <Button
          onClick={handleClick}
          className={classes.buttonText}
          style={constants.buttonDefaultStyle}
        >
          <p
            style={{
              textTransform: "lowercase",
              padding: 0,
              margin: 0,
              fontSize: 15
            }}
          >
            {label || t("table_actions:view")}
          </p>
        </Button>
        {options.length > 1 && (
          <Button
            className={classes.buttonIcon}
            variant="contained"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="menu"
            aria-label="select merge strategy"
            onClick={handleToggle}
            style={constants.buttonDefaultStyle}
          >
            <ArrowDropDown style={{ position: "inherit" }} />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1 }}
        role={undefined}
        open={open}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={event => handleMenuItemClick(event, index)}
                      disabled={
                        option ===
                          constants.tableActionOptions().cancel.label &&
                        !dataFatherIsActive
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

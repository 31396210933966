import colors from "./colors";

const styles = theme => ({
  buttonStyle: {
    margin: theme.spacing(1),
    [theme.breakpoints.between("sm", "lg")]: {
      marginLeft: 3
    }
  },
  buttonText: {
    [theme.breakpoints.down("xs")]: {
      padding: "3px",
      fontSize: "12px"
    }
  },
  buttonIcon: {
    [theme.breakpoints.down("xs")]: {
      padding: "2px"
    }
  },

  button: {
    margin: theme.spacing(1),
    color: "#1b301b",
    textTransform: "lowercase",
    fontSize: "12px",
    marginLeft: 0
  },

  buttonDisabled: {
    margin: theme.spacing(1),
    color: colors.disabled,
    textTransform: "lowercase",
    fontSize: "12px",
    marginLeft: 0
  },

  buttonGroup: {
    margin: theme.spacing(1),
    color: "#1b301b",
    textTransform: "lowercase",
    fontSize: "12px",
    marginLeft: 0
  },

  typography: {
    fontFamily: ["Livvic", "sans-serif"].join(","),
    textTransform: "lowercase",
    marginBottom: "5px",
    marginLeft: "5px"
  },

  default: {
    margin: theme.spacing(1),
    backgroundColor: "#006660",
    color: "#fff",
    textTransform: "lowercase"
  },
  defaultComponent: {
    margin: theme.spacing(1)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex"
  },
  button1: {
    margin: theme.spacing(1),
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: 3
    }
  },

  // buttonDisabled: {
  //   margin: theme.spacing(1),
  //   color: colors.dark1,
  //   textTransform: "lowercase"
  // },
  textField1: {
    width: "100%",
    boxSizing: "border-box",
    margin: theme.spacing(1),
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: theme.spacing(0) // Aplicando margem à esquerda somente em larguras específicas
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0) // Ajuste para telas pequenas, para evitar excesso de margem
    }
  },
  textField: {
    width: "100%",
    margin: theme.spacing(1)
  },
  textFieldNormal: {
    margin: theme.spacing(1)
  },
  textFieldEmail: {
    margin: theme.spacing(1)
  },
  textFieldLocker: {
    margin: theme.spacing(1),
    width: "100%"
    // height: 50
  },
  textFieldLocker1: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: "100%"
  },
  textFieldLockerInfo: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300
  },
  textFieldLockerBoxInfo: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 100
  },
  selectType: {
    margin: theme.spacing(1),
    width: "100%"
  },
  subTitle: {
    margin: theme.spacing(1)
  },
  subTitle2: {
    margin: theme.spacing(1),
    color: colors.DarkGreen
  },
  progress: {
    margin: theme.spacing(2)
  },

  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },

  paperHome: {
    width: "30%",
    height: 300,
    margin: theme.spacing(2),
    padding: theme.spacing.unit * 3,
    backgroundColor: colors.background,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)"
    }
  },

  paperHomeGrah: {
    height: 300,
    margin: theme.spacing(1),
    padding: 10,
    paddingBottom: 20,
    backgroundColor: colors.background,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)"
    }
  },

  paperHomeCentered: {
    padding: theme.spacing(3),
    backgroundColor: colors.background,
    // boxShadow: "none",
    // "&:hover": {
    //   boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)"
    // },
    boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)",
    margin: "auto",
    maxWidth: 500,
    cursor: "pointer"
  },

  box: {
    backgroundColor: colors.background
  },

  paperSmall: {
    height: 70,
    maxHeight: 50,
    margin: theme.spacing(1),
    padding: theme.spacing.unit * 2,
    boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.3)"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  paperMedium: {
    height: 50,
    margin: theme.spacing(1),
    padding: theme.spacing.unit * 3,
    boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.3)"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  paper: {
    width: "100%",
    height: 500,
    padding: theme.spacing.unit * 3,
    // boxShadow: '3px 3px 15px 1px rgba(0,0,0,0.2)',
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.3)"
    },
    display: "flex",
    flexDirection: "column"
  },

  paper2: {
    width: "100%",
    // height: 300,
    padding: theme.spacing.unit * 3,
    // boxShadow: '3px 3px 15px 1px rgba(0,0,0,0.2)',
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.3)"
    },
    display: "flex",
    flexDirection: "column"
  },

  paperNotFound: {
    width: "50%",
    height: 400,
    padding: theme.spacing.unit * 3,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.3)"
    },
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  paperScroll: {
    display: "flex",
    flexDirection: "column",
    height: 300,
    maxHeight: 300,
    overflow: "auto",
    margin: theme.spacing(1),
    backgroundColor: colors.background,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)"
    }
  },

  paperDefault: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },

  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },

  paperItemDelivery: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.background,
    boxShadow: "none",
    marginBottom: 5,
    borderBottom: `1px solid ${colors.dark0}`,
    borderRadius: 0,
    paddingBottom: 5
  },

  leftIcon: {
    marginRight: theme.spacing(1)
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: colors.primary
  }
});

export default styles;
